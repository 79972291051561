// Traditional method
// return `fields-${handles.join('][')}`;
export const getFormieId = (handles) => `${handles.join('-')}`;

// Traditional method
// return `fields[${handles.join('][')}]`;
export const getFormieName = (handles) => {
    const first = handles.shift();

    if (handles.length) {
        return `${first}[${handles.join('][')}]`;
    }

    return first;
};

export const formieAttributesTable = (table) => {
    const attrs = {};

    if (table && Array.isArray(table)) {
        table.forEach((row) => {
            attrs[row.label] = row.value;
        });
    }

    return attrs;
};
